@import 'styles/includes.scss';

.Root {
    &--OffersPage {
        margin-bottom: 64px;

        @include media(ml) {
            margin-bottom: 132px;
        }

        @include media(xxl) {
            margin-top: 80px;
            margin-left: -100px;
        }
    }

    &--StartPage {
        .Text {
            display: none;
        }
    }
}

.Header {
    @include media(ml) {
        margin-top: 0;
    }

    @include media(xl) {
        display: flex;
        flex-direction: row;
        margin-bottom: 48px;
    }
}

.Title {
    @include h2-headline;

    padding-bottom: 8px;

    @include media(m) {
        padding-bottom: 16px;
        width: 300px;
    }

    @include media(l) {
        width: 368px;
        padding-right: 48px;
    }
}

.Text {
    @include body-copy-small;
    max-width: 420px;

    @include media(m) {
        @include body-copy;
        max-width: 270px;
        padding-bottom: 16px;
    }

    @include media(l) {
        max-width: 342px;
    }

    @include media(xl) {
        padding-top: 20px;
        padding-right: 16px;
    }
}

.CtaWrapper {
    @include media(m) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @include media(ml) {
        flex-wrap: nowrap;
    }
}

.ImageWrap {
    display: block;
    position: relative;
    width: 100%;
    height: auto;

    margin-top: 32px;

    text-align: center;

    @include media(ml) {
        width: 536px;
        height: auto;

        margin-top: 0;
        margin-left: 80px;
    }

    @include media(l) {
        margin-left: 216px;
    }
}

.Image {
    position: relative;
    width: 100%;
    height: auto;

    display: inline-block;

    @include media(ml) {
        position: absolute;
        max-width: 90%;
        top: -208px;
        left: 0;
    }

    @include media(l) {
        top: -250px;
        max-width: 100%;
        left: 0;
    }
}

%ctaWysiwyg {
    h3 {
        @include small-labels;
        margin-bottom: 8px;
    }

    a {
        @include standard-link;
        color: $color-black;
        &:hover {
            color: $color-weird-green;
        }
    }

    ul, ol {
        margin-top: 16px;
    }

    li {
        position: relative;
        padding-left: 24px;

        &:before {
            position: absolute;
            content: ' ';
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            width: 0;
            height: 0;

            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 12px solid $color-weird-green;
        }
    }
}

.CtaLeft {
    @include body-copy;
    margin-top: 32px;

    @include media(m) {
        margin-top: 0;
        padding-right: 48px;
        width: 368px;
    }

    @extend %ctaWysiwyg;
}

.CtaRight {
    @include body-copy;

    margin-top: 32px;
    @include media(m) {
        margin-top: 0;
        max-width: 342px;
    }

    @extend %ctaWysiwyg;
}
